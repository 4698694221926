import React, { useState, useEffect } from "react"
import { Redirect } from "@reach/router"
import useSiteMetaData from "../hooks/useSiteMetadata"

export default () => {
  const [redirectTo, setRedirectTo] = useState(null)
  const { lsItemName, defaultLanguage } = useSiteMetaData()

  useEffect(() => {
    const langPref = localStorage.getItem(lsItemName)
    if (langPref) {
      return setRedirectTo(`/${langPref}`)
    }
    return setRedirectTo(`/${defaultLanguage}`)
    // eslint-disable-next-line
  }, [])

  return redirectTo ? <Redirect to={redirectTo} noThrow /> : null
}
